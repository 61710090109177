<template>
  <div class="s-page">
    <van-nav-bar
      fixed
      placeholder
      :title="$t('assets.messageDetails')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="s-content">
      <div class="title">{{ info.title }}</div>
      <div class="content" v-html="info.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'messageDetails',
  data() {
    return {
      id: this.$route.query.id,
      info: {}
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { data } = await this.$http.get('/home/user/msgdetail', {
        params: {
          id: this.id
        }
      })
      if (data) {
        if (data.code === 200) {
          // console.log(data.data.info)
          this.info = data.data.info
        }
      }
    },
  },
}
</script>

<style scoped lang="less">
.s-content{
  text-align: left;
  background: #fff;
  padding: 10px;
  .title{
    text-align: center;
    color: #000;
  }
  .content {
    color: #999999;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
